import {graphql} from "gatsby"
import React from "react"
import Layout from "../Page"
import ContactUs from "../../components/ContactUs"
import config from "../../config/config"
import { getUser, isLoggedIn } from "../../services/auth"

const Page = ({ data: { silverStripeDataObject }}) => {
    const host = config.host
    var newAssetsPath = '"' + host + "\/assets";
    const { title, content, canViewType, showInSearch } = silverStripeDataObject.SilverStripeSiteTree;
    var validPermissions = ['Anyone', 'Inherit'];
    if(!validPermissions.includes(canViewType)){
        if(!isLoggedIn()) {
            if (typeof window !== 'undefined') {
                window.location.href = "/login/";
            }
        }
    }
    var parseContent = content.replace(/\"\/assets/g, newAssetsPath);
    const { bannerTitle, bannerSubTitle, bannerImageURL, metaTags} = silverStripeDataObject.Page;
    
    const pageData = {
        pageClass: 'ContactPage', 
        bodyClass: '', 
        pageTitle: '',
        metaTags: metaTags,      
    };
    return (                
        <Layout pageData={pageData} title={title}>
            <section className="section">
                <div className="container">
                    <h2 className="title no-underline text-center">{title}</h2>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div dangerouslySetInnerHTML={{__html: parseContent}} />
                        </div>
                    </div>
               </div>
            </section>
            {/* <ContactUs /> */}
        </Layout>
    );
};

export default Page

export const pageQuery = graphql`
    query ($link: String!) {
        silverStripeDataObject(link: { eq: $link }) {
            SilverStripeSiteTree {
                title
                content
                canViewType
                showInSearch
            },
            Page{
                bannerTitle
                bannerSubTitle
                bannerImageURL
                metaTags
            }
        }
    }
`;